<ag-grid-angular
  (gridReady)="gridReady($event)"
  (rowClicked)="handleRowClicked($event)"
  [columnDefs]="colDefs()"
  [datasource]="dataSource"
  [paginationPageSizeSelector]="[
    25, 50, 100, 250, 500, 1000, 2500, 5000, 10000,
  ]"
  [pagination]="true"
  [style.height]="height()"
  [theme]="theme"
  rowModelType="infinite"
  style="width: 100%"
></ag-grid-angular>

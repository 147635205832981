<h1
  class="mb-4 mt-8 bg-fuchsia-700 bg-clip-text text-2xl font-bold leading-normal text-transparent md:text-3xl"
>
  Technical support
</h1>
<div
  class="grid max-w-4xl grid-cols-1 place-items-center gap-8 md:grid-cols-2 lg:grid-cols-3"
>
  <a
    alt="Single Sign On & Token Based Authentication - Auth0"
    href="https://auth0.com/?utm_source=oss&utm_medium=gp&utm_campaign=oss"
    target="_blank"
    ><img
      alt="JWT Auth for open source projects"
      height="50"
      ngSrc="//cdn.auth0.com/oss/badges/a0-badge-dark.png"
      width="150"
  /></a>
  <a
    href="https://simpleanalytics.com/tumi.esn.world?utm_source=tumi.esn.world&utm_content=badge"
    referrerpolicy="origin"
    target="_blank"
    ><img
      alt="Simple Analytics"
      crossorigin="anonymous"
      height="50"
      loading="lazy"
      ngSrc="https://simpleanalyticsbadges.com/tumi.esn.world?mode=dark&radius=8"
      referrerpolicy="no-referrer"
      width="210"
  /></a>
  <div>
    <a href="https://www.browserstack.com/" target="_blank"
      ><img
        alt="Browserstack Logo"
        height="32"
        ngSrc="/assets/logos/supporters/browserstack_logo.svg"
        width="150"
    /></a>
    <span class="text-sm">Device-tested using BrowserStack</span>
  </div>
</div>
<p class="my-8 max-w-5xl leading-normal md:text-lg">
  This app is supported by generous service providers that have a heart for open
  source and non profit projects.
</p>
